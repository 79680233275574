<template>
  <div class="app-context">
    <v-container>
        <v-row justify="center" style="margin-top: 57px;">
          <v-col cols="1">
          </v-col>
          <v-col cols="10" class="pl-0 pr-0">
            <v-row>
              <v-col cols="12">
                <span class="title notice-title">
                  {{ detailInfo.title }}
                </span>
              </v-col>
            </v-row>
            <v-row v-if="detailInfo.title != null">
              <v-col class="notice-sub" cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-row class="notice-sub-row" >
                      <v-col md="9" cols="8" class="text-left notice-sub-col">
                        공지사항
                      </v-col>
                      <v-col md="3" cols="4" class="text-right notice-sub-col">
                        <p style="margin-right: 20px;">{{ detailInfo.creDt }}</p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="item-list">
                    <div class="item-list-conts" v-html="detailConts"></div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" class="text-center">
                <div class="list-empty">
                  <div class="spinner" v-if="isChildLoading">
                    <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
                      <beat-loader
                        :loading="isChildLoading"
                        :color="loader.color"
                        :size="loader.size"
                      ></beat-loader>
                    </transition>
                  </div>
                  <div class="item">
                    <div class="logo-wrap">
                      <img src="@/assets/images/logo_unplugged.svg" />
                    </div>
                    <h4>잘못된 접근입니다.</h4>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row justify="center" style="margin-bottom: 80px;">
          <v-col cols="10" class="pl-0 pr-0">
            <v-row>
              <v-col class="next-prev-item" cols="12" v-if="prev" @click="goDetail(prev.noticeId)">
                <v-row class="next-prev-item-row">
                  <v-col cols="2" class="text-left">
                    <p class="next-prev-item-prop">이전</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="next-prev-item-title">{{prev.title}}</p>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <p class="next-prev-item-date" >{{prev.creDt}}</p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="next-prev-item" cols="12" v-if="next" @click="goDetail(next.noticeId)">
                <v-row class="next-prev-item-row">
                  <v-col cols="2" class="text-left">
                    <p class="next-prev-item-prop">다음</p>
                  </v-col>
                  <v-col cols="6">
                    <p class="next-prev-item-title">{{next.title}}</p>
                  </v-col>
                  <v-col cols="4" class="text-right">
                    <p class="next-prev-item-date">{{next.creDt}}</p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" class="text-center">
            <v-btn
              @click="goPrev()"
              rounded
              color="#146F83"
              dark
              class="notice-list-btn"
              elevation="0"
            >
              목록
            </v-btn>
          </v-col>
        </v-row>
    </v-container>

    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import SideMenu from "@/components/sidemenu/SideMenu";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    DefaultPopup,
    SideMenu,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      sideSet: {
        menuName: "introduce",
        sideTitle: "회사소개",
      },
      detailInfo: [],
      popupSet: {},
      setCount: false,
      detailConts: "",
      next: "",
      prev: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    //   window.onpopstate = () => {
    //     this.handleGoback();
    //   };
    // }
    // console.log("his", history.pushState);
    this.fetchData(this.$route.params.code);
  },
  mounted() {
    // for (
    //   let i = 0;
    //   i < document.querySelector(".introduce-menu").childElementCount;
    //   i++
    // ) {
    //   if (
    //     document
    //       .querySelector(".introduce-menu")
    //       .childNodes[i].classList.contains("active")
    //   ) {
    //     document
    //       .querySelector(".introduce-menu")
    //       .childNodes[i].classList.remove("active");
    //   }
    // }
    // document
    //   .querySelector(".introduce-menu")
    //   .childNodes[2].classList.add("active");
  },
  computed: {
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
    ...mapGetters("note", ["GET_NOTE_IDENTITY"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapActions("notice", ["NOTICE_DETAIL"]),
    download() {
      if (this.detailInfo.attchId !== null && this.detailInfo.attchId !== "") {
        window.open(
          "https://www.drrefer.net/api/pic/" + this.detailInfo.attchId,
          "_blank"
        );
      }
    },
    async fetchData(param) {
      try {
        const payload = {
          noticeId: param,
        };
        this.NOTICE_DETAIL(payload).then((data) => {
          this.detailConts= "";
          this.next= "";
          this.prev= "";

          for (let i = 0; data.data.length > i; i++) {
            if (Number(data.data[i].noticeId) === Number(param)) {
              this.detailInfo = data.data[i];
              this.detailConts = data.data[i].conts.replaceAll('\n', "<br/>");
            } else if(Number(data.data[i].noticeId) > Number(param)) {
              this.prev = data.data[i];
            } else if(Number(data.data[i].noticeId) < Number(param)) {
              this.next = data.data[i];
            }
          }
        });
      } catch (error) {
        console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async goDetail(param) {
      await this.$router.push(`/v/notice/noticeDetail/${param}`)
      .then(
        this.fetchData(param).then(
          window.scrollTo(0, 0)
        )
      )
      .catch(()=>{});
    },
    goPrev() {
      //this.$router.go(-1);
      this.$router.push("/v/notice/noticeList").catch(()=>{});
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/v/notice/noticeList").catch(()=>{});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
@import "../../assets/scss/NoticeDetail.scss";


</style>
